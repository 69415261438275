import React from "react";
import Anchor from "@ui/anchor";
import { ExternalLink } from "react-feather";
import { StaticImage } from "gatsby-plugin-image";

const LightDemo = () => {
    return (
        <div className="content">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/main-demo-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-white-version">
                                        Main Demo
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-technician-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-technician-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-technician-white-version">
                                        Technician
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-model-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-model-v2-white.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-model-white-version">
                                        Model
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-consulting-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-consulting-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-consulting-white-version">
                                        Consulting
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-fashion-designer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/fashion-designer-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-fashion-designer-white-version">
                                        Fashion Designer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-developer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/developer-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-developer-white-version">
                                        Developer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-instructor-fitness-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/instructor-fitness-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-instructor-fitness-white-version">
                                        Instructor Fitness
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-web-developer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-model-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-web-developer-white-version">
                                        Web Developer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-designer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-video-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-designer-white-version">
                                        Designer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-content-writer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/text-rotet-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-content-writer-white-version">
                                        Content Writer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-instructor-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-boxed-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-instructor-white-version">
                                        Instructor
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-freelancer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-sticky-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-freelancer-white-version">
                                        Freelancer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-photographer-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-bg-image-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-photographer-white-version">
                                        Photographer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-politician-white-version">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/front-end-white-version.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-politician-white-version">
                                        Politician
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo coming-soon">
                        <div className="inner">
                            <div className="thumbnail">
                                <a href="#!">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/coming-soon.png"
                                        alt="Personal Portfolio"
                                    />
                                </a>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <a href="#!">Accountant</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LightDemo;
