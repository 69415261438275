import React from "react";
import Anchor from "@ui/anchor";
import { ExternalLink } from "react-feather";
import { StaticImage } from "gatsby-plugin-image";

const DarkDemo = () => {
    return (
        <div className="content">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/main-demo.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/">Main Demo</Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-technician">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-technician.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-technician">
                                        Technician
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-model">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-model-v2.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-model">Model</Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-consulting">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-consulting.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-consulting">
                                        Consulting
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-fashion-designer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/fashion-designer.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-fashion-designer">
                                        Fashion Designer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-developer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/developer.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-developer">
                                        Developer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-instructor-fitness">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/instructor-fitness.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-instructor-fitness">
                                        Instructor Fitness
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-web-developer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-model.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-web-developer">
                                        Web Developer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-designer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-video.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-designer">
                                        Designer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-content-writer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/text-rotet.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-content-writer">
                                        Content Writer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-instructor">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-boxed.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-instructor">
                                        Instructor
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-freelencer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/home-sticky.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-freelencer">
                                        Freelancer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-photographer">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/index-bg-image.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-photographer">
                                        Photographer
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo">
                        <div className="inner">
                            <div className="thumbnail">
                                <Anchor path="/index-politician">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/front-end.png"
                                        alt="Personal Portfolio"
                                    />
                                    <span className="overlay-content">
                                        <span className="overlay-text">
                                            View Demo <ExternalLink size={16} />
                                        </span>
                                    </span>
                                </Anchor>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <Anchor path="/index-politician">
                                        Politician
                                    </Anchor>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-demo coming-soon">
                        <div className="inner">
                            <div className="thumbnail">
                                <a href="#!">
                                    <StaticImage
                                        className="w-100"
                                        src="../../assets/images/demo/coming-soon.png"
                                        alt="Personal Portfolio"
                                    />
                                </a>
                            </div>
                            <div className="inner">
                                <h3 className="title">
                                    <a href="#!">Accountant</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DarkDemo;
